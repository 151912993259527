import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  highlightedIndex: null,
};

const narrationSlice = createSlice({
  name: 'narration',
  initialState,
  reducers: {
    setHighlightedIndex: (state, action) => {
      state.highlightedIndex = action.payload;
    },
    resetHighlightedIndex: (state) => {
      state.highlightedIndex = null;
    },
  },
});

export const { setHighlightedIndex, resetHighlightedIndex } = narrationSlice.actions;
export default narrationSlice.reducer;
