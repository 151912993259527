import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPage: 0,
};

const storyPageSlice = createSlice({
  name: 'storyPage',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    nextPage: (state) => {
      state.currentPage += 1;
    },
    prevPage: (state) => {
      state.currentPage -= 1;
    },
  },
});

export const { setCurrentPage, nextPage, prevPage } = storyPageSlice.actions;
export default storyPageSlice.reducer;
