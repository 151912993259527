import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import Footer from '../../Pages/Home/Footer';
import DrawerComponent from './DrawerComponent';
import UserInfo from './UserInfo';

const Layout = ({ mobileOpen, handleDrawerToggle, user }) => {
  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <CssBaseline />

      {/* Sidebar (DrawerComponent) is always visible */}
      <DrawerComponent
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: '#ffffff',
          padding: '20px',
          marginLeft: { sm: '240px' },  // Always leave space for the sidebar
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          {user ? (
            <UserInfo user={user} />
          ) : (
            <Button
              variant="contained"
              color="primary"
              component={NavLink}
              to="/login"
            >
              Login
            </Button>
          )}
        </Box>
        <Outlet />
        <Footer />
      </Box>
    </Box>
  );
};

export default Layout;
