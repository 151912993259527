import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  musicOn: true,
  themeMode: 'light',
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    toggleMusic: (state) => {
      state.musicOn = !state.musicOn;
    },
    toggleThemeMode: (state) => {
      state.themeMode = state.themeMode === 'light' ? 'dark' : 'light';
    },
    setThemeMode: (state, action) => {
      state.themeMode = action.payload;
    },
    setMusicOn: (state, action) => {
      state.musicOn = action.payload;
    },
  },
});

export const { toggleMusic, toggleThemeMode, setThemeMode, setMusicOn } = settingsSlice.actions;
export default settingsSlice.reducer;
