import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from '../Common/Constants';
const URL = config.url;
// Thunk to fetch a story by ID
export const fetchStoryById = createAsyncThunk(
  'StoryModification/fetchById',
  async (storyId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${URL}/storiesModification/${storyId}`);
      const data = response.data;
      console.log('Fetched data:', data);

      // Parse the 'storybook' JSON string if necessary
      if (data && typeof data === 'string') {
        try {
          data = JSON.parse(data);
        } catch (error) {
          console.error('Error parsing storybook JSON:', error);
          return rejectWithValue('Invalid JSON format');
        }
      }

      return data;
    } catch (error) {
      console.error('Error fetching story:', error);
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch story');
    }
  }
);

// Thunk to save the story
export const saveStory = createAsyncThunk(
  'StoryModification/save',
  async (storyData, { rejectWithValue }) => {
    try {
      // Prepare data to send to the server
      const dataToSave = {
        ...storyData,
        storybook: JSON.stringify(storyData), // Stringify the storybook
      };

      // Log the data being sent to the server
      console.log('Data being sent to server:', dataToSave);

      await axios.put(
        `${URL}/storiesModification/${storyData._id}`,
        dataToSave
      );

      // Return the original storyData
      return storyData;
    } catch (error) {
      console.error('Error saving story:', error);
      return rejectWithValue(error.response?.data?.message || 'Failed to save story');
    }
  }
);

const storyModificationSlice = createSlice({
  name: 'StoryModification',
  initialState: {
    storybook: null, // Use storybook as top-level object
    loading: false,
    error: null,
  },
  reducers: {
    updateStorybookField: (state, action) => {
      const { field, value } = action.payload;
      if (state.storybook) {
        state.storybook[field] = value;
      }
    },
    updateStorybookPage: (state, action) => {
      const { pageIndex, pageData } = action.payload;
      if (state.storybook && state.storybook.pages) {
        state.storybook.pages[pageIndex] = pageData;
      }
    },
    updatePageField: (state, action) => {
      const { pageIndex, field, value } = action.payload;
      if (state.storybook && state.storybook.pages && state.storybook.pages[pageIndex]) {
        state.storybook.pages[pageIndex] = {
          ...state.storybook.pages[pageIndex],
          [field]: value,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoryById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStoryById.fulfilled, (state, action) => {
        state.loading = false;
        state.storybook = action.payload; // Set storybook at top-level
      })
      .addCase(fetchStoryById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(saveStory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveStory.fulfilled, (state) => {
        state.loading = false;
        // No need to update state.storybook, as it's already updated via reducers
      })
      .addCase(saveStory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export const { updateStorybookField, updateStorybookPage, updatePageField  } = storyModificationSlice.actions;
export default storyModificationSlice.reducer;
